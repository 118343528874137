.app {
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.main {
  display: flex;
  flex-direction: row;
  width: 100%;
}

@media screen and (max-width: 1680px) {
  .app {
    max-width: 1200px;
  }
  .content {
    width: calc(1200px - 215px);
  }
}

@media screen and (max-width: 1280px) {
  .app {
    max-width: 1024px;
  }
  .content {
    width: calc(1024px - 215px);
  }
}

@media screen and (max-width: 1024px) {
  .app {
    max-width: 768px;
  }
  .content {
    width: calc(768px - 215px);
  }
}

.content {
  padding-top: 15px;
  width: calc(1280px - 215px);
}


