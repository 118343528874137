body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h3 {
  color: #333;
  font-size: initial;
  font-weight: bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: #4a69bd;
  cursor: pointer;
}
a:visited {
  color: #4a69bd;
}

select {
  background: gainsboro;
  padding: 0.2em;
  border-radius: 2px;
  border: 1px solid lightgrey;
}

.view {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.view-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.view-title {
  flex: 1 1;
  align-self: end;
  color: #333;
  font-weight: 400;
  line-height: 1.1;
  font-size: 32px;
  margin: 0;
}

.view-paragraph-title {
  flex: 1 1 0%;
  color: #333;
  font-weight: 400;
  line-height: 1.3;
  font-size: 24px;
  margin: 2px 0 10px 0;
}

.view-subtitle {
  flex: 1 1;
  color: #777;
  font-size: 14px;
  margin: 2px 0 10px 0;
}

.view-info {
  margin: 8px 0 0 0;
  font-size: smaller;
  display: block;
  color: #555;
}

.breadcrumb{
  display: block;
  font-size: 13px;
}
.breadcrumb a i {
  position: relative;
  top: 1px;
}

.tip {
  flex-basis: 100%;
  padding: 10px 20px;
  color: #555;
  background: aliceblue;
  border-radius: 2px;
  border: 1px solid lightsteelblue;
  font-size: 14px;
  text-align: justify;
  line-height: 1.6em;
}

.button {
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  padding: 12px 18px;
  margin-top: 0;
  margin-left: 0;
  text-transform: uppercase;
  white-space: nowrap;
  letter-spacing: 1px;
  font-size: 13px;
  font-weight: 500;
  color: ghostwhite;
  opacity: 1;
  cursor: pointer;
  background: #4a69bd;
  border: 1px solid #4a69bd;
  border-radius: 3px;
  transition: border-color 0.25s ease 0s, background 0.25s ease 0s;
}
.small {
  text-transform: initial;
  font-weight: bold;
  padding: 8px 12px;
}
.button-warning {
  background: orangered;
  border: 1px solid orangered;
}
.button-disabled {
  cursor: default;
  background: #bbb;
  border: 1px solid #bbb;
}
.primary-key {
  padding-left: 10px;
}

.breadcrumb .material-symbols-outlined{
  font-size: 10px;
  font-weight: bold;
}

.danger-zone {
  padding: 20px 30px;
  border: 1px solid orangered;
  border-radius: 2px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: space-between;
  font-size: smaller;
}

.danger-zone div h4 {
  color: orangered;
  margin: 0 0 10px 0;
}
.danger-zone div p {
  color: #555;
  margin: 5px 0 0 0;
}

.form-property-label {
  margin-top: 5px;
  font-size: small;
  padding: 2px 0;
  color: #888;
}
.form-property-value {
  padding-bottom: 2px;
  height: 27px;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}